<template>
  <div class="pc reserva reserva-contrato">

    <div v-if="isPreLoad" class="preload">
      <div class="preload-wrapper"></div>
      <div class="preload-dots">
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>  
    </div>

    <header class="p-3"
      v-if="bookingContract && booking.reservation"
    >
      <h1 v-cloak>
        <router-link  :to="{ name: 'Booking', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}">
          <span>
            <i class="fal fa-chevron-left pr-1"></i> 
          </span>
          {{ translator.contracte }}
        </router-link>
      </h1>
    </header>

    <div v-if="bookingContract.host" class="contracte container">
      <div class="logo" v-if="user.user_type === 'checkin'">
        <img :src="translator.logo" class="img-fluid" />
      </div>

      <h5 v-cloak>{{ bookingContract.headline }}</h5>

      <hr />

      <!-- Anfitrión -->
      <h5 v-cloak>{{ translator.amfitrio }}</h5>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.nom }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.host.host_name }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.adreca }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.host.host_address }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.dip }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.host.host_zip }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.poble }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.host.host_city }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.cif }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.host.host_tin }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.telefon }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.host.host_phone }}
        </div>
      </div>

      <hr />

      <!-- Huésped -->
      <h5 v-cloak>{{ translator.hoste }}</h5>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.nom }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.guest.guest_name }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.adreca }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.guest.guest_address }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.dip }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.guest.guest_zip }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.poble }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.guest.guest_city }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.pais }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.guest.guest_country }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.passaport }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.guest.guest_tin }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.telefon }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.guest.guest_phone }}
        </div>
      </div>

      <hr />

      <!-- Localización -->
      <h5 v-cloak>{{ translator.localitzacio }}</h5>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.referencia }}</div>
        <div class="col-7 dada" v-cloak>
          <!-- {{ bookingContract.property.property_name }} -->
          {{ booking.lodging_name }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.adreca }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.property.property_address }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.ciutat }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.property.property_zip }} -
          {{ bookingContract.property.property_city }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.telefon }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.property.property_phone }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.registre }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.property.property_license }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.capacitat }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.property.property_capacity }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.arribada }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.reservation.arrival_date }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.sortida }}</div>
        <div class="col-7 dada" v-cloak>
          {{ bookingContract.reservation.departure_date }}
        </div>
      </div>

      <hr />

      <!-- Reserva -->
      <h5 v-cloak>
        {{ translator.reserva }}
        {{ bookingContract.reservation.reservation_id }}
      </h5>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.quantitat }}</div>
        <div class="col-4 dada text-right" v-cloak>
          {{ bookingContract.reservation.total_amount }}
          {{ bookingContract.reservation.currency }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.carrecneteja }}</div>
        <div class="col-4 dada text-right" v-cloak>
          {{ bookingContract.reservation.cleaning_fee }}
          {{ bookingContract.reservation.currency }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.taxaturistica }}</div>
        <div class="col-4 dada text-right" v-cloak>
          {{ bookingContract.reservation.tourist_tax }}
          {{ bookingContract.reservation.currency }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.pagat }}</div>
        <div class="col-4 dada text-right" v-cloak>
          {{ bookingContract.reservation.paid }}
          {{ bookingContract.reservation.currency }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.perpagar }}</div>
        <div class="col-4 dada text-right" v-cloak>
          {{ bookingContract.reservation.pending_payment }}
          {{ bookingContract.reservation.currency }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 label" v-cloak>{{ translator.diposit }}</div>
        <div class="col-4 dada text-right" v-cloak>
          {{ bookingContract.reservation.security_deposit }}
          {{ bookingContract.reservation.currency }}
        </div>
      </div>

      <hr />

      <a
        class="d-flex justify-content-between align-items-center"
        data-toggle="collapse"
        href="#collapse"
        role="button"
        aria-expanded="false"
        aria-controls="collapse"
        @click="arrowContract === true ? arrowContract = false : arrowContract = true"
      >
        <h5 class="mb-0" v-cloak>{{ translator.condicions }}</h5>
        <i class="fas fa-chevron-up" v-if="arrowContract" style="color: var(--colores-gris-medio);"></i>
        <i class="fas fa-chevron-down" v-else style="color: var(--colores-gris-medio);"></i>
      </a>
      <div class="collapse" id="collapse">
        <div
          class="card card-body px-0"
          v-html="bookingContract.reservation.general_conditions"
          v-cloak
        ></div>
      </div>

      <hr />

      <h5 v-cloak>{{ translator.signaturaestabliment }}</h5>
      <img :src="bookingContract.host.host_signature" class="img-fluid" />

      <hr />

      <h5 v-cloak>{{ translator.signaturaclient }}</h5>

      <img
        v-if="guestSignature != ''"
        :src="guestSignature"
        class="img-fluid"
      />

      <!-- <div
        v-if="
          isRegisterDocument &&
          (booking.mandatory_guests_contract == 'false' || isAllRegisterAdults) &&
          (booking.mandatory_signatures_contract == 'false' || isAllSignAdults )  
        "
      >
        <a
          v-if="guestSignature == ''"
          class="btn btn-secondary mt-3"
          data-toggle="modal"
          data-target="#Modal"
          @click="initCanvas"
          v-cloak
          >{{ translator.afegirsignatura }}</a
        >
      </div> -->
      <div class="mt-3">
        <!-- Alerta si no tiene los datos de la reserva rellenada -->
        <div v-if="!isRegisterDocument && booking.mandatory_guests_contract == 'false' && guestSignature == ''">
          <div class="alert alert-danger" role="alert">
            {{ translator.persignarcontracte }}
          </div>
          <button class="btn btn-secondary" disabled v-cloak>
            {{ translator.afegirsignatura }}
          </button>
        </div>
        <!-- Alerta Datos reserva + personas adultas -->
        <div v-else-if="!isAllRegisterAdults && booking.mandatory_guests_contract == 'true' && booking.mandatory_signatures_contract == 'false' && guestSignature == '' & !booking._registered_adults_only_completed">
          <div class="alert alert-danger" role="alert">
            {{ translator.persignarcontracte }}
          </div>
            <button class="btn btn-secondary" disabled v-cloak>
              {{ translator.afegirsignatura }}
            </button>
        </div>
        <!-- Alerta Datos reserva + personas adultas + firmas -->
        <div v-else-if="(!isAllSignAdults || !booking._registered_adults_only_completed) && booking.mandatory_guests_contract == 'true' && booking.mandatory_signatures_contract == 'true' && guestSignature == ''">
          <div class="alert alert-danger" role="alert">
            {{ translator.persignarcontractehostesfirma }}
          </div>
            <button class="btn btn-secondary" disabled v-cloak>
              {{ translator.afegirsignatura }}
            </button>
        </div>     
        <!-- Botón firma habilitado -->
        <div v-else>
          <a v-if="guestSignature == ''"
            class="btn btn-secondary mt-3"
            data-toggle="modal"
            data-target="#Modal"
            @click="initCanvas"
            v-cloak
            >{{ translator.afegirsignatura }}
          </a>
        </div>     
      </div>

      <!-- Modal firma -->
      <div
        class="modal fade"
        id="Modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="ModalLabel" v-cloak>
                {{ translator.signar }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="clearCanvas()"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center">
              <canvas
                id="sketchpad"
                height="200"
                width="300"
                style="border: 1px solid #ccc"
              ></canvas>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-secondary"
                @click="clearCanvas()"
                v-cloak
              >
                {{ translator.esborrar }}
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                :class="{'disabled': isCanvasClear}"
                @click="saveCanvas"
                v-cloak
              >
                {{ translator.guardar }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Fin modal firma -->
    </div>
  </div>
</template>

<script>
var canvas, ctx;
var mouseX, mouseY, mouseDown = 0;
var touchX, touchY;
var lastX, lastY = -1;

import { mapState } from "vuex";
const $ = require('jquery');

// Firma android //
let scrollY = '';
$(document).on('show.bs.modal','#Modal', function () {
  if( navigator.userAgent.match(/Android/i)) {
    const is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    is_firefox ? 
      document.documentElement.mozRequestFullScreen() : 
      document.documentElement.webkitRequestFullscreen();
  }
  scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
  const body = document.body;
  body.style.position = 'fixed';
  body.style.width = '100%';
  body.style.top = `-${scrollY}`;
});
$(document).on('hidden.bs.modal','#Modal', function () {
  if( navigator.userAgent.match(/Android/i)) {
    const is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    is_firefox ? 
      document.mozCancelFullScreen() : 
      document.webkitExitFullscreen();
  }
  const body = document.body;
  body.style.position = '';
  body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0'));
});
window.addEventListener('scroll', () => {
  document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
});
// //

export default {
    name: "bookingContract",
    computed: {
      ...mapState("utils", ["user", "translator"]),
      ...mapState("checkin", ["booking", "bookingContract", "bookingGuests"]),
    },
    watch: {
      bookingContract(){
        this.guestSignature = this.bookingContract.guest.guest_signature;
      },
      translator() {
        this.$store.dispatch("checkin/getHostes", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId });
        this.$store.dispatch( "checkin/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId });     
        this.$store.dispatch( "checkin/getBookingContract", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId });
      },
      booking() {
        setTimeout(()=>{
          this.checkStatusBooking();
          this.checkAdultsAge();
          this.isPreLoad = false;
        }, 200)
      }
    },
    data() {
        return {
            isPreLoad: false,
            arrowContract: false,
            guestSignature: '',
            isRegisterDocument: false,
            isAllRegisterAdults : false,
            isAllRegisterChildren: false,
            isAllSignAdults: false,
            isAllSignChildren: false,
            // Canvas en blanco
            canvasClear: '',
            isCanvasClear: true
        };
    },
    async created() {
      this.isPreLoad = true;
      try {
        if ( this.translator ) {
          await this.$store.dispatch("checkin/getHostes", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId });
          await this.$store.dispatch( "checkin/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId });     
          await this.$store.dispatch( "checkin/getBookingContract", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId });
        } else {
          await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
        }
           
      } catch (error) {
          console.error(error);
      } finally {
          //this.isPreLoad = false;
      }
    },
    methods: {
      //Mira si el registro esta completado
      checkStatusBooking() {
        this.booking.customer_address == '' ||
        this.booking.customer_city == '' ||
        this.booking.customer_country == '' ||
        this.booking.customer_email == '' ||
        this.booking.customer_first_name == '' ||
        this.booking.customer_last_name == '' ||
        this.booking.customer_phone == '' ||
        this.booking.customer_tin == '' ||
        this.booking.customer_zip == '' 
        ? this.isRegisterDocument = false 
        : this.isRegisterDocument = true; 
        console.log('Reserva rellenada?', this.isRegisterDocument)
      },
      //Mira la edad de los adultos
      checkAdultsAge() {
        let registerAdults = 0, 
        registerChildren = 0,
        signAdults = 0,
        signChildren = 0,
        totalSigns = 0;

        this.bookingGuests.forEach(guest => {
          guest._isSign ? totalSigns ++ : '';
          guest._isAdult ? registerAdults ++ : registerChildren ++;
          guest._isSign && guest._isAdult ? signAdults ++ : signChildren ++;
        });

        // Adultos
        parseInt(this.booking.adults) === registerAdults ? this.isAllRegisterAdults = true : '';
        parseInt(this.booking.adults) === signAdults || totalSigns >= parseInt(this.booking.adults) ? this.isAllSignAdults = true : ''; //modo restrictivo quitar || totalSigns >=...
        // Niños
        parseInt(this.booking.children) === registerChildren ? this.isAllRegisterChildren = true : '';
        parseInt(this.booking.children) === signChildren ? this.isAllSignChildren = true : '';

        console.log('total firmas >= al núm. adultos ?', this.isAllSignAdults)
      },

      // Canvas para firma
      initCanvas() {
        canvas = document.getElementById('sketchpad');

        if (canvas.getContext)
          ctx = canvas.getContext('2d');

        if (ctx) {
          canvas.addEventListener('mousedown', this.sketchpad_mouseDown, false);
          canvas.addEventListener('mousemove', this.sketchpad_mouseMove, false);
          window.addEventListener('mouseup', this.sketchpad_mouseUp, false);

          canvas.addEventListener('touchstart', this.sketchpad_touchStart, false);
          canvas.addEventListener('touchend', this.sketchpad_touchEnd, false);
          canvas.addEventListener('touchmove', this.sketchpad_touchMove, false);
        }
        this.canvasClear = document.getElementById('sketchpad').toDataURL('image/png');
        this.isCanvasClear = true;
      },
      drawLine(ctx, x, y, size) {

        if (lastX == -1) {
          lastX = x;
          lastY = y;
        }

        let r = 0, g = 102, b = 179, a = 255;

        ctx.strokeStyle = "rgba(" + r + "," + g + "," + b + "," + (a / 255) + ")";
        ctx.lineCap = "round";
        ctx.beginPath();
        ctx.moveTo(lastX, lastY);
        ctx.lineTo(x, y);
        ctx.lineWidth = size;
        ctx.lineCap = 'round';
        ctx.stroke();
        ctx.closePath();
        lastX = x;
        lastY = y;
        this.isCanvasClear = false;
      },
      sketchpad_mouseDown() {
        mouseDown = 1;
        this.drawLine(ctx, mouseX, mouseY, 4);
      },
      sketchpad_mouseUp() {
        mouseDown = 0;
        lastX = -1;
        lastY = -1;
      },
      sketchpad_mouseMove(e) {
        this.getMousePos(e);

        if (mouseDown == 1) {
          this.drawLine(ctx, mouseX, mouseY, 4);
        }
      },
      getMousePos(e) {
        if (!e)
            //var e = event;
          console.log(e)///No borrar
        if (e.offsetX) {
          mouseX = e.offsetX;
          mouseY = e.offsetY;
        }
        else if (e.layerX) {
          mouseX = e.layerX;
          mouseY = e.layerY;
        }
      },
      sketchpad_touchStart() {
        this.getTouchPos();
        this.drawLine(ctx, touchX, touchY, 4);
        //event.preventDefault();
      },
      sketchpad_touchEnd() {
        lastX = -1;
        lastY = -1;
      },
      sketchpad_touchMove(e) {
        this.getTouchPos(e);
        this.drawLine(ctx, touchX, touchY, 4);
        //event.preventDefault();
      },
      getTouchPos(e) {
        if (!e)
          //var e = event;
          console.log(e)///No borrar
        if (e.touches) {
          if (e.touches.length == 1) {
            var touch = e.touches[0];

            if (screen.width <= 430) { //Iphone's 6/7/8, Iphones's Plus 6/7/8 y Iphone X
              touchX = touch.pageX - touch.target.offsetLeft - 10;
              touchY = touch.clientY - touch.target.offsetTop - 75;
            } else if (screen.width >= 538 && screen.width <= 569) { //suferce Duo
              touchX = touch.pageX - touch.target.offsetLeft - 10;
              touchY = touch.clientY - touch.target.offsetTop - 75;
            } else if (screen.width > 766 && screen.width <= 800) {// ipad
              touchX = touch.pageX - touch.target.offsetLeft - 135;
              touchY = touch.clientY - touch.target.offsetTop - 90;
            }
            else if (screen.width > 1022 && screen.width <= 1040) {// ipadPro
              touchX = touch.pageX - touch.target.offsetLeft - 265;
              touchY = touch.clientY - touch.target.offsetTop - 90;
            }
          }
        }
      },
      //Borrar Canvas
      clearCanvas() {
        canvas = document.getElementById('sketchpad');
        if (canvas.getContext){
          ctx = canvas.getContext('2d');
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        this.canvasClear = document.getElementById('sketchpad').toDataURL('image/png');
        this.isCanvasClear = true;
      },
      //Guardar firmna
      async saveCanvas() {
        this.isPreLoad = true;
        try {
          const guestSign = document.getElementById('sketchpad').toDataURL('image/png');
          if (guestSign != this.canvasClear) {
            await this.$store.dispatch( "checkin/postContracteSignatura", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId, jpg: guestSign, ip: '', language: this.user.user_language });
            this.guestSignature = guestSign;
            $('#Modal').modal('hide');
            this.$toast.success(this.translator.guardat);
            this.clearCanvas();
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.isPreLoad = false;
        }
      },
    }
};
</script>

<style>
.reserva-contrato header {
    background: var(--white);
    font-size: 0.9rem;
}
</style>